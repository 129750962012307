import { Box, Stack } from '@northvolt/ui'
import type { AttributeCollection, AttributeCollectionLight, AttributeLight } from 'client/model'
import { DataType } from 'components/Utils'
import React, { useEffect } from 'react'
import AttributeSelector from '../Attribute/AttributeSelector'

interface StratificationControlProps {
  attributeCollections: AttributeCollectionLight[]
  stratificationAttributeName: string | undefined
  defaultChecked?: boolean
  setStratificationAttributeName: (attributeName: string | undefined) => void
}

const StratificationControl: React.FC<StratificationControlProps> = ({
  attributeCollections,
  stratificationAttributeName,
  setStratificationAttributeName,
}) => {
  const defaultStratifcationAttribute: AttributeLight = {
    column_name: 'sample_name',
    data_type: DataType.STRING,
    id: -1,
    grain: 'Prismatic Cell',
    unique_name: 'sample_name',
    qualified_name: 'sample_name',
  }
  const [allOptions, setAllOptions] = React.useState([
    {
      name: 'Default',
      attributes: [defaultStratifcationAttribute],
      grain: 'Prismatic Cell',
    } as AttributeCollection,
    ...attributeCollections,
  ])
  const [selectedOption, setSelectedOption] = React.useState<AttributeLight | undefined>(undefined)

  useEffect(() => {
    setAllOptions([
      {
        name: 'Default',
        attributes: [defaultStratifcationAttribute],
        grain: 'Prismatic Cell',
      } as AttributeCollection,
      ...attributeCollections,
    ])
  }, [attributeCollections])

  useEffect(() => {
    setSelectedOption(
      allOptions.reduce(
        (acc, { attributes }) =>
          attributes?.find(({ unique_name }) => acc ?? unique_name === stratificationAttributeName),
        undefined as AttributeLight | undefined,
      ),
    )
  }, [stratificationAttributeName])

  return (
    <>
      <Stack spacing={2} direction={'row'}>
        {true && (
          <Box sx={{ width: '100%' }}>
            <AttributeSelector
              attributeCollections={allOptions}
              selectedAttributes={selectedOption ? [selectedOption] : []}
              setSelectedAttributes={attrs => {
                setStratificationAttributeName(attrs[0].unique_name)
                setSelectedOption(attrs[0])
              }}
              label='Stratification attribute'
              multiple={false}
              dataTypeWhitelist={[DataType.STRING, DataType.BOOLEAN]}
              tooltip={'Select the attribute to stratify the data by, or empty to not stratify .'}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}

export default StratificationControl
