/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AttributeSemantics,
  AttributeStatistics,
  DataLoadingParamsCreate,
  HTTPValidationError,
  LoadWombatFromDatasetParams,
  PaginatedResponseUserPlot,
  ReadUserPlotsParams,
  ScatterForm,
  ScatterFormCreate,
  ScatterResultData,
  UserPlot,
  WombatExportFormCreate,
  WombatExportResultData
} from '.././model'



/**
 * @summary Load Wombat From Dataset
 */
export const loadWombatFromDataset = (
    datasetId: number,
    params: LoadWombatFromDatasetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.get(
      `/api/wombat/load_dataset/${datasetId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getLoadWombatFromDatasetQueryKey = (datasetId: number,
    params: LoadWombatFromDatasetParams,) => {
    return [`/api/wombat/load_dataset/${datasetId}`, ...(params ? [params]: [])] as const;
    }

    
export const getLoadWombatFromDatasetQueryOptions = <TData = Awaited<ReturnType<typeof loadWombatFromDataset>>, TError = AxiosError<HTTPValidationError>>(datasetId: number,
    params: LoadWombatFromDatasetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLoadWombatFromDatasetQueryKey(datasetId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof loadWombatFromDataset>>> = ({ signal }) => loadWombatFromDataset(datasetId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(datasetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData> & { queryKey: QueryKey }
}

export type LoadWombatFromDatasetQueryResult = NonNullable<Awaited<ReturnType<typeof loadWombatFromDataset>>>
export type LoadWombatFromDatasetQueryError = AxiosError<HTTPValidationError>


/**
 * @summary Load Wombat From Dataset
 */

export function useLoadWombatFromDataset<TData = Awaited<ReturnType<typeof loadWombatFromDataset>>, TError = AxiosError<HTTPValidationError>>(
 datasetId: number,
    params: LoadWombatFromDatasetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLoadWombatFromDatasetQueryOptions(datasetId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Load Wombat From Collection Samples
 */
export const loadWombatFromCollectionSamples = (
    dataLoadingParamsCreate: DataLoadingParamsCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.post(
      `/api/wombat/load_collection_samples/`,
      dataLoadingParamsCreate,options
    );
  }



export const getLoadWombatFromCollectionSamplesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, {data: DataLoadingParamsCreate}> = (props) => {
          const {data} = props ?? {};

          return  loadWombatFromCollectionSamples(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoadWombatFromCollectionSamplesMutationResult = NonNullable<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>>
    export type LoadWombatFromCollectionSamplesMutationBody = DataLoadingParamsCreate
    export type LoadWombatFromCollectionSamplesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Load Wombat From Collection Samples
 */
export const useLoadWombatFromCollectionSamples = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>,
        TError,
        {data: DataLoadingParamsCreate},
        TContext
      > => {

      const mutationOptions = getLoadWombatFromCollectionSamplesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Statistics
 */
export const readAttributeStatistics = (
    attributeId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeStatistics>> => {
    
    return axios.default.get(
      `/api/wombat/attribute_statistics/${attributeId}`,options
    );
  }


export const getReadAttributeStatisticsQueryKey = (attributeId: number,) => {
    return [`/api/wombat/attribute_statistics/${attributeId}`] as const;
    }

    
export const getReadAttributeStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeStatistics>>, TError = AxiosError<HTTPValidationError>>(attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeStatisticsQueryKey(attributeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeStatistics>>> = ({ signal }) => readAttributeStatistics(attributeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeStatistics>>>
export type ReadAttributeStatisticsQueryError = AxiosError<HTTPValidationError>


/**
 * @summary Read Attribute Statistics
 */

export function useReadAttributeStatistics<TData = Awaited<ReturnType<typeof readAttributeStatistics>>, TError = AxiosError<HTTPValidationError>>(
 attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getReadAttributeStatisticsQueryOptions(attributeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Load Scatter
 */
export const loadScatter = (
    scatterFormCreate: ScatterFormCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScatterResultData>> => {
    
    return axios.default.post(
      `/api/wombat/load_scatter/`,
      scatterFormCreate,options
    );
  }



export const getLoadScatterMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadScatter>>, TError,{data: ScatterFormCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loadScatter>>, TError,{data: ScatterFormCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loadScatter>>, {data: ScatterFormCreate}> = (props) => {
          const {data} = props ?? {};

          return  loadScatter(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoadScatterMutationResult = NonNullable<Awaited<ReturnType<typeof loadScatter>>>
    export type LoadScatterMutationBody = ScatterFormCreate
    export type LoadScatterMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Load Scatter
 */
export const useLoadScatter = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadScatter>>, TError,{data: ScatterFormCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof loadScatter>>,
        TError,
        {data: ScatterFormCreate},
        TContext
      > => {

      const mutationOptions = getLoadScatterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read User Plots
 */
export const readUserPlots = (
    params?: ReadUserPlotsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseUserPlot>> => {
    
    return axios.default.post(
      `/api/wombat/user_plots`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getReadUserPlotsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readUserPlots>>, TError,{params?: ReadUserPlotsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof readUserPlots>>, TError,{params?: ReadUserPlotsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof readUserPlots>>, {params?: ReadUserPlotsParams}> = (props) => {
          const {params} = props ?? {};

          return  readUserPlots(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReadUserPlotsMutationResult = NonNullable<Awaited<ReturnType<typeof readUserPlots>>>
    
    export type ReadUserPlotsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Read User Plots
 */
export const useReadUserPlots = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readUserPlots>>, TError,{params?: ReadUserPlotsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof readUserPlots>>,
        TError,
        {params?: ReadUserPlotsParams},
        TContext
      > => {

      const mutationOptions = getReadUserPlotsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Semantics
 */
export const readAttributeSemantics = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeSemantics[]>> => {
    
    return axios.default.get(
      `/api/wombat/semantics`,options
    );
  }


export const getReadAttributeSemanticsQueryKey = () => {
    return [`/api/wombat/semantics`] as const;
    }

    
export const getReadAttributeSemanticsQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeSemantics>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeSemantics>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeSemanticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeSemantics>>> = ({ signal }) => readAttributeSemantics({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeSemantics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeSemanticsQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeSemantics>>>
export type ReadAttributeSemanticsQueryError = AxiosError<unknown>


/**
 * @summary Read Attribute Semantics
 */

export function useReadAttributeSemantics<TData = Awaited<ReturnType<typeof readAttributeSemantics>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeSemantics>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getReadAttributeSemanticsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Scatter Form
 */
export const readScatterForm = (
    hash: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScatterForm>> => {
    
    return axios.default.get(
      `/api/wombat/read_scatter_form/${hash}`,options
    );
  }


export const getReadScatterFormQueryKey = (hash: string,) => {
    return [`/api/wombat/read_scatter_form/${hash}`] as const;
    }

    
export const getReadScatterFormQueryOptions = <TData = Awaited<ReturnType<typeof readScatterForm>>, TError = AxiosError<HTTPValidationError>>(hash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readScatterForm>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadScatterFormQueryKey(hash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readScatterForm>>> = ({ signal }) => readScatterForm(hash, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(hash), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readScatterForm>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadScatterFormQueryResult = NonNullable<Awaited<ReturnType<typeof readScatterForm>>>
export type ReadScatterFormQueryError = AxiosError<HTTPValidationError>


/**
 * @summary Read Scatter Form
 */

export function useReadScatterForm<TData = Awaited<ReturnType<typeof readScatterForm>>, TError = AxiosError<HTTPValidationError>>(
 hash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readScatterForm>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getReadScatterFormQueryOptions(hash,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Wombat Export
 */
export const wombatExport = (
    wombatExportFormCreate: WombatExportFormCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WombatExportResultData>> => {
    
    return axios.default.post(
      `/api/wombat/wombat-export`,
      wombatExportFormCreate,options
    );
  }



export const getWombatExportMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wombatExport>>, TError,{data: WombatExportFormCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof wombatExport>>, TError,{data: WombatExportFormCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wombatExport>>, {data: WombatExportFormCreate}> = (props) => {
          const {data} = props ?? {};

          return  wombatExport(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WombatExportMutationResult = NonNullable<Awaited<ReturnType<typeof wombatExport>>>
    export type WombatExportMutationBody = WombatExportFormCreate
    export type WombatExportMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Wombat Export
 */
export const useWombatExport = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wombatExport>>, TError,{data: WombatExportFormCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof wombatExport>>,
        TError,
        {data: WombatExportFormCreate},
        TContext
      > => {

      const mutationOptions = getWombatExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Toggle User Plot Favorite
 */
export const toggleUserPlotFavorite = (
    plotId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPlot>> => {
    
    return axios.default.post(
      `/api/wombat/user_plots/${plotId}/favorite`,undefined,options
    );
  }



export const getToggleUserPlotFavoriteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleUserPlotFavorite>>, TError,{plotId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof toggleUserPlotFavorite>>, TError,{plotId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof toggleUserPlotFavorite>>, {plotId: number}> = (props) => {
          const {plotId} = props ?? {};

          return  toggleUserPlotFavorite(plotId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ToggleUserPlotFavoriteMutationResult = NonNullable<Awaited<ReturnType<typeof toggleUserPlotFavorite>>>
    
    export type ToggleUserPlotFavoriteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Toggle User Plot Favorite
 */
export const useToggleUserPlotFavorite = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleUserPlotFavorite>>, TError,{plotId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof toggleUserPlotFavorite>>,
        TError,
        {plotId: number},
        TContext
      > => {

      const mutationOptions = getToggleUserPlotFavoriteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    